<template>
<b-container fluid>
  <b-row class="text-white bg-kpmg-blue py-4 mb-4">
    <b-col>
      <h1 class="kstyle">Types</h1>
    </b-col>
  </b-row>
  <b-row>
    <b-container>
      <b-row class="text-center mb-4" align-h="center" v-if="loading">
        <b-col cols="1">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="!loading">
        <b-col>
          <b-card class="mb-3">
            <b-form-group horizontal class="mt-4">
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to search" />
                  <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
              </b-input-group>
            </b-form-group>
          </b-card>

          <b-card >
            <div v-for="item in show" :key="'item' + item.id">
              <div class="mb-3">
                <b-link :to="{ name: 'NewsTag', params: { id: item.id }}">{{item.name}}</b-link>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Types',
  computed: {
    show: function () {
      let result = this.items
      if (this.filter.length > 0) {
        result = _.filter(result, x => {
          return x.name.toUpperCase().includes(this.filter.toUpperCase())
        })
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Types"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'types', action: 'open types' })
    this.load()
  },
  data () {
    return {
      buttonvariant: 'primary',
      event: {},
      filter: '',
      loading: true,
      items: ''
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('load started')
      this.loading = true
      try {
        this.items = await this.$Amplify.API.get('cosmos', '/tags')
        this.loading = false
        this.$logger.debug(this.items)
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
